.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: #18181b;
    transform: translateY(0);
    transition: transform 0.3s ease-in-out;
    z-index: 1000;
  }
  
  .header-content {
    color: white;
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 32px;
  }
  
  .socials a {
    margin-right: 20px;
    margin-bottom: 20px;
    color: white;
  }
  
  .nav-links a {
    margin-left: 20px;
    color: white;
  }
  