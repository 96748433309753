.footer {
    background-color: #18181b;
    color: white;
    text-align: center;
    padding: 16px;
  }

  .footer-content {
    max-width: 1280px;
    margin: 0 auto;
  }
