.landing-section {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2A4365;
    color: white;
    height: 100vh;
    text-align: center;
  }
  
  .landing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .avatar {
    border-radius: 50%;
    width: 150px;
    height: 150px;
    margin-bottom: 20px;
  }
  
  h1, h2 {
    margin: 10px 0;
  }
  
  .bio-container {
    width: 350px; 
  }