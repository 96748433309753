.projects-section {
    background-color: #14532d;
    color: white;
    padding: 64px 32px;
    text-align: left;
  }

  .projects-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 32px;
  }

  h1 {
    margin-bottom: 32px;
  }
